
import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Watch} from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import {IAccountIdentity, ServiceFeesResponse} from '@/services/dto/IDtos';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import DisplayEnumUtils from '@/utils/DisplayEnumUtils';

@Component({
  components: {
    UserCard,
  },
})

export default class UserDelete extends AbstractComponentVue {
  private accountIdentity: IAccountIdentity = {} as IAccountIdentity;
  private accountId: number = this.$route.params.accountId as unknown as number;
  private user: IUserResponse = {} as IUserResponse;
  private isAccountInProgress: boolean = false;
  private mailDelayRemoval: number = 0;
  private login: string = '';
  private userServicesResilationFees: ServiceFeesResponse[] = [];

  private userCardMounted: boolean = true;

  private beforeMount(): void {
    this.fetchData();
  }

  // Lorsque l'url change, on récupère les nouvelles données pour mettre à jour la page
  @Watch('$route', {immediate: true, deep: true})
  private onUrlChange(newVal: any) {
    this.fetchData();
    // Décharge et recharge le composant UserCard.
    this.accountId = this.$route.params.accountId as unknown as number;
    this.userCardMounted = false;
    setInterval(() => {
      this.userCardMounted = true;
    }, 100);
  }

  private fetchData(): void {
    this.showModal('modal-traitement-cours');
    services.restService.callGet('/api/v1/accounts/' + this.$route.params.accountId)
        .then((response: any) => {
          this.user = response.data;
          this.accountIdentity.accountId = this.user.accountId;
          this.accountIdentity.firstName = this.user.firstName as string;
          this.accountIdentity.lastName = this.user.lastName as string;
          this.accountIdentity.roleType = this.user.authorities ? this.user.authorities[0] : '';
          this.accountIdentity.admin = this.user.admin;
          this.accountIdentity.entityName = this.user.entityName as string;
          this.user.userServices.forEach((userService) => {
            // Si un service est BEING_VALIDATED alors on ne peut modifier l'identité du user
            if (userService.serviceType !== 'MER' && userService.state === 'BEING_VALIDATED') {
              this.isAccountInProgress = true;
            }
            if (userService.serviceType !== 'MAIL') {
              this.login = userService.login;
            }
          });
          services.restService.callGet('/api/v1/user-services/mail/removal-delay')
              .then((respMail): any => {
                this.mailDelayRemoval = respMail.data;
              });

          services.restService.callGet('/api/v1/accounts/' + this.$route.params.accountId
              + '/services/fees')
              .then((respServfee): any => {
                this.userServicesResilationFees = respServfee.data;
              });
        })
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }

  private deleteUser() {
    this.showModal('modal-traitement-cours');
    services.restService.callDelete('/api/v1/accounts/' + this.$route.params.accountId)
        .then((response): any => {
          if (response !== undefined && response.status === 200) {
            const isEntity = this.accountIdentity.roleType === 'ROLE_ENTITY';
            let message: string;
            if (isEntity) {
              message = 'L\'entité ' + this.accountIdentity.entityName + ' a bien été supprimée.';
            } else {
              message = 'L\'utilisateur ' + this.accountIdentity.firstName + ' '
                  + this.accountIdentity.lastName + ' a bien été supprimé.';
            }

            AlertService.success(message);
            services.routageService.goTo('/account/list');
          } else {
            AlertService.error('Une erreur s\'est produite pendant la suppression de l\'utilisateur '
                + this.accountIdentity.firstName + ' ' + this.accountIdentity.lastName);
          }
        })
        .catch((respError) => {
          console.error(respError);
          AlertService.error('Une erreur s\'est produite pendant la suppression de l\'utilisateur '
              + this.accountIdentity.firstName + ' ' + this.accountIdentity.lastName);
        })
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }

  private created() {
    // Interdire la page pour que la personne connectée pour qu'elle ne puisse pas se supprimer elle-même.
    const accountToDeleteId: string = this.$route.params.accountId;
    if ((this.$store.getters.connectedUserAccountId + '') === accountToDeleteId) {
      services.routageService.goTo('/home');
    }
  }

  /**
   * Permet d'afficher le nom du service
   */
  private getDisplayName(serviceType: string): string {
    return DisplayEnumUtils.getDisplayName(serviceType);
  }
}
